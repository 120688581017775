<template>
  <div>
    <div class="ft-container">
      <featured-placeholder v-if="!contents" />
      <div
        class='feature-corousel-container'
        @mouseover="contentCursorHover"
        @mouseout="contentCursorNotHover"
      >
        <!-- <div class="ft-sep"></div> -->
        <div class="featured-carousel" :id="`featured-carousel`">
          <div class="ft-card" v-for="(content, key) in contents" :key="key">
            <!-- :style="{'height': `${cardHeight}px`}" -->
            <div class="ft-card-box">
              <div class="img-div">
                <img
                  :src="defaultImage"
                  :data-src="getPoster(content)"
                  alt="carousal"
                  class="ft-card-box-contain"
                  @click="detailClick(content.contentid,key)"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="ft-nav-container" id="ft-nav-container" @click="navBtnClickCB">
          <button
            type="button"
            v-for="(number, key) in navigationKeys"
            :key="key"
          ></button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { tns } from "tiny-slider/src/tiny-slider.module";
import { mapGetters, mapMutations, mapActions } from "vuex";
import appMixins from "@/mixins/appMixins";
import lazyLoader from "@/mixins/lazyLoader";
import { EventBus } from "@/main";
import { formatDateString } from '@/mixins/dateSupport'

let responsiveData={
              1490: {
                items: 3,
                gutter: 15,
                edgePadding: 30,
              },
              1400: {
                items: 1,
                gutter: 61,
                edgePadding: 411,
              },
              1200: {
                items: 1,
                gutter: 50,
                edgePadding: 332,
              },
              992: {
                items: 1,
                gutter: 40,
                edgePadding: 253,
              },
              768: {
                items: 1,
                gutter: 38,
                edgePadding: 196,
              },
              576: {
                items: 1,
                gutter: 28,
                edgePadding: 117,
              },
              320: {
                items: 1,
                gutter: 15,
                edgePadding: 25,
              }
            }

export default {
  name: "FeaturedCarousel",
  data() {
    return {
      contents: null,
      slider: null,
      cardHeight: 500,
      hoverPaused: false,
      defaultImage: require("@/assets/icons/placeholder-landscape.png"),
      navigationKeys:0
    };
  },
  props: ["category", "displayDetails"],
  computed: {
    ...mapGetters(["contentList"])
  },
  watch:{
    contentList(val){
      if(!Array.isArray(val))return;
      this.contents=JSON.parse(JSON.stringify(val));
      this.constructCarousel(this.contents);
     }
   },
  methods: {
    constructCarousel(contentList) {
      // this.contents = contentList;
      contentList.sort(function compare(a, b) {
        var dateA = new Date( formatDateString( a.created ) );
        var dateB = new Date( formatDateString( b.created ) );
        return dateB - dateA;
      });

      this.contents = contentList;
      this.layoutSwitch();

      let isLoop = false;
      let autoplay = false;
      if (contentList.length > 1) {
        isLoop = true;
        autoplay = true;
      }
      // let isRewind = true;
      // if (contentList.length < 3) {
      //   isRewind = true;
      // }

      // if (index) {
      //   autoplay = false;
      //   isLoop = false;
      // }
      if (document.getElementById("featured-carousel") && document.getElementById("ft-nav-container")) {
        this.$nextTick(() => {
          if (this.slider)return;
          
          this.slider = tns({
            container: `#featured-carousel`,
            slideBy: "page",
            nav: true,
            navContainer: "#ft-nav-container",
            autoplay: autoplay,
            autoplayHoverPause: false,
            autoplayButtonOutput: false,
            autoHeight: true,
            freezable: false,
            loop: isLoop,
            rewind: false,
            controls: false,
            autoplayTimeout: 2000,
            responsive: responsiveData,
            preventScrollOnTouch: 'forced'
          });

          //getting all slider images....
          let sliderComponentImages = [
            ...document
              .getElementById("featured-carousel")
              .querySelectorAll(".ft-card.tns-item"),
          ].map((el) => el.querySelector(".ft-card-box-contain"));

          let srcSetter = this.getObserver(); //get observer if available else load image.
          sliderComponentImages.forEach((el) => {
            srcSetter(el);
          });
        });
      }
    },
    closeContentDetail() {
      this.hoverPaused=false;
      this.slider && this.slider.play && this.slider.play();
      EventBus.$off('detailCardClosed',this.closeContentDetail);
      // EventBus.$emit("popupClosed");
      // EventBus.$emit("changeSliderContainer", false);
    },
    getPoster(content) {
      if (content.posters.length) {
        let landscapeposters = content.posters.filter(
          (pos) => pos.orientation === "LANDSCAPE"
        );
        if (landscapeposters.length) {
          let posterIndex = landscapeposters.findIndex(
            (pos) => pos.quality === "SD"
          );
          if (posterIndex > -1) {
            return landscapeposters[posterIndex].url;
          } else {
            return landscapeposters[0].url;
          }
        }
      }
      // else {
      //   return content.poster;
      // }
    },
    navBtnClickCB() {
      setTimeout(() => {
        !this.hoverPaused && this.slider.play();
      }, 1000);
    },
    detailClick(contentid,key) {
      if (isNaN(Number(key)) || !contentid) return;
      key = Number(key);
      if (key === 0 || key === this.contents.length - 1) {
        //for end case slides...

        let diff = this.slider.getInfo().navCurrentIndex - key;

        if (Math.abs(diff) === this.contents.length - 1)
          key = diff > 0 ? "next" : "prev";
      }
      this.slider.goTo(key);
      let content = this.contents.find(
        (content) => content.contentid === contentid
      );
      if (content) {
        this.slider.pause && this.slider.pause();
        // EventBus.$emit("openDetailCard", {detailCard:content});
        this.routeToCardView(content.contentid);
        EventBus.$on('detailCardClosed',this.closeContentDetail);
       // this.showDetail(content);
        //EventBus.$emit("changeSliderContainer", true);
      }
    },
    contentCursorHover(e) {
      if (this.slider && e.target.classList.contains("ft-card-box-contain")) {
        this.slider.pause();
        this.hoverPaused = true;
      }
    },
    contentCursorNotHover(e) {
      if (
        this.slider &&
        !this.$route.name.includes('Card') &&
        e.target.classList.contains("ft-card-box-contain")
      ) {
        if (this.slider.play) this.slider.play();
        this.hoverPaused = false;
      }
    },
    fetchCardHeight() {
      let bodyWidth = document.body.offsetWidth - 500;
      let cardHeight = Math.round(bodyWidth / (16 / 9));
      if (cardHeight) {
        this.cardHeight = cardHeight + 10;
        if (this.isTablet()) {
          this.cardHeight += 50;
        }
      }
    },
    layoutSwitch(){
      let width=window.screen && window.screen.width;
      let itemsCount=1;
      for(let [breakpoint,data] of Object.entries(responsiveData).reverse()){
          if(width>=Number(breakpoint)){
            itemsCount=data.items;
            break;
          }
        }
      this.navigationKeys=this.contents?parseInt(this.contents.length /itemsCount):0;  
    }
  },
  mounted() {
    //this.constructCarousel();
    EventBus.$on("closeDetails", this.closeContentDetail);
    if (this.contentList && Array.isArray(this.contentList)) {
      this.constructCarousel(JSON.parse(JSON.stringify(this.contentList)));
    }
  },
  created() {
    this.fetchCardHeight();
    // if (this.contentList) {
    //   this.constructCarousel(this.contentList);
    // }
    // EventBus.$on("featuredCarouselData", (content) => {
    //   console.log("entered ", content);
    //   this.constructCarousel(content);
    // });
  },
  beforeDestroy() {
    EventBus.$off("closeDetails", this.closeContentDetail);
    this.slider && this.slider.destroy();
  },
  components: {
    // contentDetailCard: () =>
    //   import(
    //     /* webpackChunkName: "Detail card" */ "@/components/Shared/templates/contentDetailCard.vue"
    //   ),
    "featured-placeholder": () =>
      import(
        /* webpackChunkName: "featuredPlaceholder" */ "@/components/Carousels/featuredPlaceholder.vue"
      ),
    // "featured-description": () =>
    //   import(
    //     /* webpackChunkName: "featuredDescription" */ "@/components/Carousels/featuredDescription.vue"
    //   ),
  },
  mixins: [appMixins, lazyLoader],
};
</script>
<style >
  .feature-corousel-container .tns-ovh{
    overflow:visible;
  }
</style>
<style lang="scss" scoped>
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_mediaQueries.scss";
.ft-card-box-contain {
  max-width: 100%;
  cursor: pointer;
}
.ft-container,
.feature-corousel-container {
  height: max-content !important;
}
.detail-open .featured-content-description {
  display: none;
}
.details-card {
  width: 86%;
  margin-left: 7%;
  margin-top: 40px;
}
.active-content {
  border: 2px solid $theme-primary;
  border-radius: 12px;
  &::after {
    content: "";
    border-color: $theme-primary transparent transparent transparent;
    border-style: solid;
    border-width: 13px;
    position: absolute;
    bottom: -11%;
    left: 45%;
  }
}
.feature-corousel-container {
  background: $clr-dark-hlt-6 0% 0% no-repeat padding-box;
  overflow: hidden;
}
.ft-container {
  position: relative;
  margin-bottom: 10px;
  z-index: 1;
}
.ft-sep {
  // min-height: 70px;
  min-height: 40px;
}
.ft-card {
  // opacity: 0.4;
  transition: all 0.3s ease;
  position: relative;
}
.ft-card-box {
  position: relative;
  overflow: hidden;
  .img-div {
    width:100%;
    height:0;
    position:relative;
    padding:28.125% 0;
    overflow: hidden;
  }
  &:hover {
    z-index: 1000;
    -webkit-transition: all 0.5s ease-in-out;
    -webkit-transform: scale(1.04);
    -ms-transition: all 0.5s ease-in-out;
    -ms-transform: scale(1.04);
    -moz-transition: all 0.5s ease-in-out;
    -moz-transform: scale(1.04);
    transition: all 0.5s ease-in-out;
    transform: scale(1.04);
  }
  .ft-card-box-contain {
    border-radius: 6px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  &::before {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    height: 25%;
    width: 100%;
    z-index: 9;
  }
}
.ft-nav-container {
  text-align: center;
  // padding: 10px 0;
  padding: 10px 0px 30px 0px;
  // position: relative;
  top: -46%;
  // top:-55%;
  button {
    border: 2px solid $clr-white;
    opacity: 0.5;
    width: 20px;
    height: 4px;
    border-radius: 6px;
    margin-right: 15px;
    cursor: pointer;
    &.tns-nav-active {
      background-color: $clr-white;
      opacity: 1;
      width: 44px;
    }
  }
}
.featured-share-btn {
  float: left;
  margin-right: 5px;
  .share-popup {
    width: 250px !important;
    img {
      margin-bottom: 0;
    }
  }
}
#featured-carousel-mw {
  overflow: visible;
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
}

.featured-share-btn {
  margin-right: 2px;
  .share-popup {
    width: 170px !important;
  }
}
.ft-container {
  :hover {
    #slide-feature-controls {
      opacity: 1;
      transition: 0.1s ease-in;
    }
  }
}
.featured-carousel {
  margin-top: 5%;
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@include breakpoint(max480) {

  .ft-container {
    :hover {
      #slide-feature-controls {
        opacity: 0;
      }
    }
  }
  .ft-nav-container {
    display: none;
  }
  .feature-corousel-container{
      padding-bottom:20px;
  }
}

@include breakpoint(max768) {
  .details-card {
    width: 95%;
    margin-left: 2%;
  }
  .card-title {
    font-size: 1rem;
  }
  .featured-tags {
    font-size: 0.7rem;
  }
  
  .ft-nav-container {
    top: -49%;
  }

  .ft-card-misc {
    top: 73%;
  }
  // .ft-card-box {
  //    height: 400px !important;
  // }
}
@include breakpoint(mobile) {
  // .feature-corousel-container {
  //   height: 325px;
  // }
  // .ft-container {
  //   min-height: 200px !important;
  // }
}
// @media only screen and (min-width: 600px) and (max-width: 992px) {
@include breakpoint(min600max980) {
  .ft-container {
    // min-height: 350px !important;
    margin: 0 0 15px 0;
  }
  .ft-card-box {
    // height: 350px !important;
    margin: 0px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@include breakpoint(min980max1200) {
  .ft-container {
    // min-height: 500px !important;
    margin: 0 0 15px 0;
  }
  .ft-card-box {
    // height: 500px !important;
    margin: 0px;
  }
  .ft-nav-container {
    top: -26px;
  }
  // .ft-container {
  //   height: 400px !important;
  // }
  .feature-corousel {
    margin-top: 5%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@include breakpoint(min1200) {
  .ft-container {
    // min-height: 500px !important;
    margin: 0 0 15px 0;
  }
  .ft-card-box {
    // height: 620px !important;
    margin: 0px;
  }

}

@include breakpoint(min1440) {
  // .featured-content-description {
  //   display: block !important;
  // }

  // .ft-card-box{
  //   height: 365px !important;
  // }
}

@include breakpoint(min980max1200) {
  
  .ft-nav-container {
    top: -157px;
  }
}
@include breakpoint(desktopLarge) {
  .ft-container {
    // min-height: 500px !important;
    margin: 0 0 15px 0;
  }
  .ft-card-box {
    // height: 800px !important;
    margin: 0px;
  }
  // .details-card {
  //   margin-top: 15% !important;
  // }
  .card-detail {
    margin: 0px -150px !important;
  }
}
</style>
<style lang="scss">
.feature-corousel-container #featured-carousel-mw {
  height: max-content !important;
}
</style>