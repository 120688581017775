export default {
    methods:{
        getObserver(){
            if (window["IntersectionObserver"]) {
              
              const handleIntersect=(entries, observer)=>{
                entries.forEach((entry) => {
                  if (entry.isIntersecting) {
                    this.loadImageSrc(entry.target);
                    observer.unobserve(entry.target);
                  }
                });
              }
              const options = {
                root: null,
                threshold: "0",
              };
              const observer = new IntersectionObserver(handleIntersect, options);
            
              return (el)=>{
                observer.observe(el);
              }  
      
            } else {
              return this.loadImageSrc;
            }
          },
          loadImageSrc(el){
               el.onerror=()=>{
                 el.src=this.defaultImage;
               } 
               el.src = el.dataset.src;
          },
    }
}